.convex-bottom-bar {
  display: flex;
  /* align-items: flex-end; */
  /* justify-content: space-around; */
  background-color: #1c1b1f;
  /* padding: 10px 0; */
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 80px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.3);
  z-index: 10;
}
.convex-bottom-bar-content {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  position: relative;
  width: 100%;
  /* z-index: 10; */
}
.tab-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #666;
  font-size: 12px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.tab-item .icon {
  font-size: 24px;
}

.tab-item.actived .icon {
  font-size: 30px;
  background: linear-gradient(180deg, #7aff50, #16cbe7);
  color: white;
  border-radius: 50%;
  padding: 15px;
  margin-top: -20px;
}
.tab-item.active .icon {
  /* font-size: 30px; */
  /* background: linear-gradient(180deg, #5efce8, #736efe); */
  color: #1ce85c;
  /* border-radius: 50%; */
  /* padding: 15px; */
  /* margin-top: -20px; */
}
.tab-item.actived::before {
  content: '';
  position: absolute;
  display: flex;
  justify-content: center;
  /* left: 0; */
  bottom: 99%;
  width: 8.2em;
  height: 2em;
  clip-path: url(#menu);
  will-change: transform;
  background-color: #1c1c2e;
  z-index: -1;
}
.menu__border {
  left: 42%;
  bottom: 99%;
  width: 8.2em;
  height: 2em;
  position: absolute;
  clip-path: url(#menu);
  will-change: transform;
  background-color: #1c1c2e;
  z-index: -1;
  transition: translateX(calc(50%));
  /* transition: transform var(--timeOut, var(--duration)); */
}
.tab-item.active .label {
  color: #1ce85c;
}

.tab-item.active {
  color: #fff;
}
